import Home from './Components/Home';
import Contact from './Components/Footer/Contact';
import OpeningsCard from './Components/Career/OpeningsCard';
import { Route, Switch,BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
              <Route exact path="/Openings" component={OpeningsCard} />
              <Route exact path="/" component={Home} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
      <Contact/>
    </>
  );
}

export default App;
