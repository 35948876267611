import React from 'react';
import './About.css';

function About() {
    return (
        <section id="work">
            

            <h1 className="about-header">About Us</h1>
            
            <p className="about-p">

                
                    Pumori Tech is a software company based in Chicago, Illinois.  We are located in the Lincoln Square neighborhood.
                   
                <br /><br />
                
                No work is too small or too big for us.
                From fixing a tiny bug in your existing application to building a highly-sophisticated application,
                that is very well architected, we can help you with it all.<br /><br />
                <span id="hidden-mobile">
                    At Pumori, we take pride in the software that we build and deliver.
                    We focus on the quality of the software by making sure that the code we write is clean,
                    readable, reusable, scalable and 100% covered by the automation test suite.
                    We will make sure to communicate with you very well, at every stage of the development process.
                    Depending on the need, we mostly follow the agile methodology to make sure that we are flexible and
                    can adopt to your change in requirements and to enable frequent scheduled deliveries.<br /><br />

                    It is very important to us, at Pumori, that we build you an application that is -
                    built to scale for millions of users across the globe, is highly performant even under stress,
                    is available at all times, and can easily grow along with your organization.<br /><br />

                    If you are just looking to bring in some consultants to help you with a project,
                    we can help you by providing any number of consultants with the skillset you are looking for.<br /><br />

                    Please call us or email us to schedule a free consultation.<br /><br />
                </span> 
            </p>
            <div className="items">
                <div className="item">
                    <i className="fa fa-industry fa-2x"></i>
                    <div>
                        <h3>Software Company.</h3>
                        {/* <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam,
                            sed.
                        </p> */}
                    </div>
                </div>
                <div className="item">
                    <i className="fa fa-map fa-2x" aria-hidden="true"></i>

                    <div>
                        
                        <h3>Chicago, Illinois.</h3>
                        {/* <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam,
                            sed.
                        </p> */}
                    </div>
                </div>
                <div className="item">
                    <i className="fa fa-cogs fa-2x" aria-hidden="true"></i>
                    <div>
                        <h3>Scalable Solutions.</h3>
                        {/* <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam,
                            sed.
                        </p> */}
                    </div>
                </div>
            </div>
           

        </section>

    )
}

export default About
