import React from 'react';
import logo from '../../images/pumori1.png';
import './Footer.scss';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

function Footer() {
    return (
        <div className="footer" id="contact">
            <img className="logo" src={logo} alt="logo" />
            <div className="contactInfoContainer">
                <p><MailOutlineIcon /><a href="mailto:info@pumoritech.com" className="link-white">info@pumoritech.com</a></p>
                <br />
                <p><PhoneIcon /><a href="tel:7734430706" className="link-white"><span>(773) 443-0706</span></a>
                </p>
                <br />
                <div className="footer-social">
                    <ul>
                        <li><a href="http://localhost:3000"> <i className="fa fa-linkedin-square fa-lg" aria-hidden="true"></i></a></li>
                        <li><a href="http://localhost:3000"> <i className="fa fa-facebook-official fa-lg" aria-hidden="true"></i></a></li>
                        <li><a href="http://localhost:3000"> <i className="fa fa-twitter fa-lg" aria-hidden="true"></i></a></li>
                    </ul>
                </div>

            </div >

        </div >
    )
}

export default Footer
