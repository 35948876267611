import React, { useState, useEffect } from 'react';
import '../Style.scss';
import logo from '../../images/pumori1.png';
import { Link } from "react-scroll";
import MenuIcon from '@material-ui/icons/Menu';
import './menubar.scss';
import {Link as Return} from 'react-router-dom';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

function Navbar({isHome=true}) {
    const [header, setHeader] = useState("social-header");
    const [nav, setNav] = useState("nav-container");
    const [show, setShow] = useState(false);
    // const styleMenuItems = {display:show?"block":"none",backgroundColor:"red",height:"100vh",transition: "all 5s ease-in"};

    const listenScrollEvent = event => {
        if (window.scrollY < 73) {
            setNav("nav-container")
            return setHeader("social-header");
        } else if (window.scrollY > 70) {
            setNav("nav-container2")
            return setHeader("social-header2");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);
    return (
        <>
        <div className={nav} >
            <header>
                {/* <a href="index.html" className="logo">Pumori Tech.</a> */}
                <Link
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    to="showcase"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}><img className="logo" src={logo} alt="logo" /></Link>
                {isHome? 
                <nav>
                    <ul>
                        <li>
                            <Link
                                style={{ cursor: "pointer" }}
                                activeClass="active"
                                to="work"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                About
                            </Link>
                        </li>
                        <li>
                            <Link
                                style={{ cursor: "pointer" }}
                                activeClass="active"
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                Services
                            </Link>
                        </li>
                        <li><Link
                            style={{ cursor: "pointer" }}
                            activeClass="active"
                            to="team"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >
                            Career
                        </Link></li>
                        <li><Link
                            style={{ cursor: "pointer" }}
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >
                            Contact
                        </Link></li>
                    </ul>
                   
                </nav>
                : null}

                {isHome? 
                <MenuIcon 
                    className="hamburger-icon" 
                    style={{color:"white",height:"40px",width:"auto",cursor:"pointer"}}
                    onClick= {()=>setShow(!show)}
                />
                :<Return to="/"><KeyboardReturnIcon 
                    className="hamburger-icon"
                    style={{color:"white",height:"30px",width:"auto",cursor:"pointer"}}
                    />
                </Return>}
            </header>
            <div className={header}>
                <ul>
                    <li><a href="http://localhost:3000/"> <i className="fa fa-linkedin-square fa-lg" aria-hidden="true"></i></a></li>
                    <li><a href="http://localhost:3000/"> <i className="fa fa-facebook-official fa-lg" aria-hidden="true"></i></a></li>
                    <li><a href="http://localhost:3000/"> <i className="fa fa-twitter fa-lg" aria-hidden="true"></i></a></li>
                </ul>
                
            </div>
            <div  className={show? "showing":"not-showing"}>
                <ul>
                        <li  className="item">
                            <Link
                                style={{ cursor: "pointer" }}
                                activeClass="MenuItem"
                                to="work"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                                onClick= {()=>setShow(!show)}
                            >
                                About
                            </Link>
                        </li>
                        <li  className="item">
                            <Link
                                style={{ cursor: "pointer" }}
                                activeClass="MenuItem"
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                                onClick= {()=>setShow(!show)}
                            >
                                Services
                            </Link>
                        </li>
                        <li  className="item"><Link
                            style={{ cursor: "pointer" }}
                            activeClass="MenuItem"
                            to="team"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                            onClick= {()=>setShow(!show)}
                        >
                            Career
                        </Link></li>
                        <li className="item"><Link
                            style={{ cursor: "pointer" }}
                            activeClass="MenuItem"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                            onClick= {()=>setShow(!show)}
                        >
                            Contact
                        </Link></li>
                        
                    </ul>
            </div>
            
        </div>
        
    </>
    )
}

export default Navbar;
