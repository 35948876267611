import React from 'react'
import Navbar from './Navbar/Navbar';
import About from './About/About';
import Showcase from './Showcase/Showcase';
import Services from './Services/Services';
import Career from './Career/Career';

function Home() {

    return (
        <>
        <Navbar isHome={true}/>
        <Showcase />
        <About />
        <Services />
        <Career />
        </>
    )
}

export default Home
