export const EmailClient = {
    sendContactUsForm: async (senderName, senderEmail, senderMessage) => {
        if (senderName?.length > 0 && senderEmail?.length > 0 && senderMessage?.length > 0){
            const url = `${process.env.REACT_APP_API_BASE_URL}/email/send`;
            console.log(process.env.REACT_APP_API_BASE_URL);
            const html = `
                <p>Pumori Web Contact Form</p>
                <p>From: ${senderName}</p>
                <p>Email: ${senderEmail}</p>
                <p>Message: ${senderMessage}</p>`;

            const data = { subject: 'PumoriTech Contact Us Form', html} 
            const response = fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            return response;
        }
    }
}