import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MediaCard from './MediaCard';
import "./Services.scss";
import image1 from "../../images/pumori_tech_web_development.jpg";
import image2 from "../../images/pumori_tech_mobile_development.jpg";
import image3 from "../../images/pumori_tech_code_screen.jpg";

const useStyles = makeStyles({
    serviceList: {
        padding: '15px',
        listStyleType: 'disc'
    }
});


function Services() {
    const classes = useStyles();

    function getServiceText(category){
        if (category === "application"){
            return (<>
                Whether it is a brand-new application that you need developed or 
                just a new feature you want in an existing application, we can help you with it.
                We offer application development in the following areas:

                <ul className={classes.serviceList}>
                    <li>Web application development with responsive designs</li>
                    <li>Mobile application development for iOS and Android</li>
                    <li>Windows desktop application development</li>
                </ul>
            </>);
        }
        
        if (category === "consultation"){
            return (<>
                Pumori provides consultation services for the project you need help with.  
                No matter the size of the project, we can provide you with the expert or the team of experts to get your project done.  

                A few areas we provide consultation services are as follows, please contact us for these or to learn about the other services we provide.  

                <ul className={classes.serviceList}>
                    <li>Fixing bugs in your existing application</li>
                    <li>Integration with external services like Stripe, Shopify, SSO Providers, DocuSign, Google Services, etc.</li>
                    <li>Building APIs for internal services or to allow external consumption</li>
                    <li>Application performance tuning - infrastructure, application, database</li>
                    <li>Architectural design from start to finish - Databases, Queues, Micro Services, API, Repository, Frontend, etc.</li>
                </ul>
            </>);
        }

        if (category === "uiux"){
            return (<>
                User Interface and User Experience is a critical part in any application.  
                Pumori can help you with a clean UI/UX development whether it is a brand-new application or a redesign that you are looking for. <br /><br />
		
		        Please contact us for more info.
            </>);
        }

        return "";
    }

    return (
        <section className="services" id="services">
            <div className="services-header">
                <h1 className="service-h1">What We Do</h1>
                <p>At Pumori, we develop and deliver quality software that is clean and easy to use, highly performant, and easy to maintain and scale.</p>
            </div>
            <div className="services-cards">
                <MediaCard image={image1}
                    content={getServiceText("application")}
                    title="Application Development" />
                <MediaCard image={image2}
                    content={getServiceText("consultation")}
                    title="Consultation Services" />
                <MediaCard image={image3}
                    content={getServiceText("uiux")}
                    title="UI/UX Development" />
            </div>
        </section>

    )
}

export default Services;
