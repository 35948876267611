import React from 'react';
import image from "../../images/dotnet.png"
import "./Opening.css";
import image1 from '../../images/react.png';
import image2 from '../../images/graphic.png';
import Navbar from '../Navbar/Navbar';


export default function OpeningsCard(props) {

    return (
        <>
        <Navbar isHome={false} />
        <div id="card-parent">
            <br />
        <div id="card">
            <img src={image} alt=""/>
            <div id="content">
               <p id="title">Backend Engineer [Dotnet] </p>
               
                <ol>
                <li>Required: 3</li>
                <li>C#</li>
                <li>Dotnet</li>
                <li> Postgres </li>
                </ol>
                <p id="email">Send Your Profile at: info@pumoritech.com</p> 
                
                
            </div>
        </div>
        <div id="card">
            <img src={image1} alt=""/>
            <div id="content">
               <p id="title-react">Frontend Engineer [React JS] </p>
                <ol>
                <li>Required: 2</li>
                <li>JavaScript</li>
                <li>React</li>
                <li>TypeScript</li>
                </ol>
                <p id="email">Send Your Profile at: info@pumoritech.com</p> 
            </div>
        </div>
        <div id="card">
            <img src={image2} alt=""/>
            <div id="content">
               <p id="title-graphic">Graphic Designer [Remote] </p>
                <ol>
                <li>Required: 2</li>
                <li>Photoshop</li>
                <li>Figma</li>
                <li>Eye for great designs</li>
                </ol>
                <p id="email">Send Your Profile at: info@pumoritech.com</p> 
            </div>
        </div>
        </div>
        </>
        
    );
}
