import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import {EmailClient} from "../../Clients/EmailClient";

const theme = createMuiTheme({
    root: {
        "& .MuiFilledInput-root": {
            background: "red"
        }
    },
    palette: {
        primary: {
            main: "#FE5C36",
        },
    },
});
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFilledInput-root": {
            background: "rgb(232, 241, 250)"
        }
    }
}));
function Form() {
    const classes = useStyles();
    const [mail, setMail] = useState({
        template_params: {},
        show: false,
        valid: false,
    });

    const onClickOfButton = (event) => {
        event.preventDefault();
        const {template_params} = mail;
        const response = EmailClient.sendContactUsForm(template_params.from_name, template_params.reply_to, template_params.message_html);
        if (response?.status){
            setMail({show: true});
        }
    };

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...mail.template_params,
        };
        let updatedElement = {
            ...updatedForm[inputIdentifier],
        };
        updatedElement = event.target.value;
        updatedForm[inputIdentifier] = updatedElement;
        setMail({ template_params: updatedForm, show: false });
    };
    let todisplay;
    // eslint-disable-next-line no-lone-blocks
    {
        mail.show
            ? (todisplay = (
                <>
                    <h1 style={{ color: "teal", padding: "3rem 3rem" }}>Thank you!</h1>
                    <h1
                        style={{ color: "teal", marginTop: "1rem", paddingLeft: "3rem" }}
                    >
                        Your Message Has Been Delivered.
                    </h1>
                </>
            ))
            : (todisplay = (
                <>
                    <h2 style={{ textAlign: "center" }}>
                        <span style={{ color: "white" }}>Contact Us</span>
                    </h2>

                    <div className="form">
                        <ThemeProvider theme={theme}>
                            <TextField
                                label="Your Name Here"
                                //   variant="outlined"
                                variant="filled"
                                className={classes.root}
                                fullWidth
                                style={{ background: "red !important" }}
                                //   InputProps={{
                                //     className: theme.bg
                                //   }}
                                margin="normal"
                                onChange={(event) => inputChangedHandler(event, "from_name")}
                            />
                            <TextField
                                label="Your Email Here"
                                className={classes.root}
                                variant="filled"
                                fullWidth
                                margin="normal"
                                onChange={(event) => inputChangedHandler(event, "reply_to")}
                            />

                            <TextField
                                variant="filled"
                                className={classes.root}
                                rows="4"
                                fullWidth
                                label="Your Message Here"
                                placeholder="Your Message"
                                multiline
                                margin="normal"
                                onChange={(event) =>
                                    inputChangedHandler(event, "message_html")
                                }
                            />
                        </ThemeProvider>

                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "tomato",
                                color: "white",
                                width: "20%",
                                marginTop: "1rem",
                            }}
                            onClick={(event) => {
                                onClickOfButton(event);
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </>
            ));
    }
    return <div className="contact__form">{todisplay}</div>;
}

export default Form;