import React from "react";
import "./Contact.css";
import Footer from "./Footer";
import Form from "./Form.js";

function Contact() {
  return (
    <div className="contact" id="contact">
     <Footer/>
      <Form />
    </div>
  );
}

export default Contact;