import React from 'react';
import './Career.css';
import {
    Link
  } from 'react-router-dom';

function Career() {
    return (
    
    <section id="team">
        <div id="team-members">
            <h1 className="subtitle">Want To Join Us</h1>
            <br/>
            <p className="career-p">
                Pumori Tech is always looking to hire the best. 
                If you are passionate about building high quality software and you are good at it, we have work for you. <br /><br />
                Please apply for one of our open positions.
            </p>
            <br/>
            
            <Link to="/Openings" className="view" href="career.html">View open positions </Link>
        </div>

        <div id="team-image"></div>
    </section>
    )
}

export default Career
